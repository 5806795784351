import CorporateActionsCalendarStore from './CorporateActionsCalendarStore';
import TailwindPaginationStore from '../Pagination/TailwindPaginationStore';

/**
 * コーポレートアクション株式分割ルートストア
 *
 * @module CorporateActions/CorporateActionsSplitStore
 */
const CorporateActionsSplitStore = {
  modules: {
    corporateActionsCalendar: CorporateActionsCalendarStore,
    tailwindPagination: TailwindPaginationStore,
  },
};
export default CorporateActionsSplitStore;
