import MkApolloProvider from '../../../utils/MkApolloProvider';
import CorporateActionsCalendar from './CorporateActionsCalendar.vue';
import CorporateActionsStockTransfers from './CorporateActionsStockTransfers.vue';

/**
 * 株式分割一覧表示用のルートコンポーネント
 * @module CorporateActions/CorporateActionsSplit
 */
const CorporateActionsSplit = {
  el: '#v-corporate-action-split',
  apolloProvider: MkApolloProvider,
  components: {
    CorporateActionsCalendar,
    CorporateActionsStockTransfers,
  },
}

export default CorporateActionsSplit;
