<template src="./CorporateActionsCalendar.html"></template>
<script>
import dayjs from 'dayjs';
import { QUERY_CORPORATE_ACTIONS_CALENDAR } from '../../../queries/CorporateActionsCalendarQuery';
import CorporateActionsMonthPicker from './CorporateActionsMonthPicker.vue';

const actionNames = {
  split: '分割',
  reverseSplit: '併合',
  delisting: '上場廃止',
};

export default {
  name: 'CorporateActionsCalendar',
  components: {
    CorporateActionsMonthPicker,
  },
  props: {
    actionType: {
      type: String,
      require: true,
    },
    todayString: {
      type: String,
      require: true,
    },
    calendarPeriodFrom: {
      type: String,
      require: true,
    },
    calendarPeriodTo: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      actionName: actionNames[this.actionType],
      corporateActionsCalendar: [],
    };
  },
  computed: {
    today() {
      return dayjs(this.todayString);
    },
    weeks() {
      return this.corporateActionsCalendar ? this.corporateActionsCalendar.weeks : [];
    },
    actions() {
      return this.corporateActionsCalendar ? this.corporateActionsCalendar.actions : [];
    },
    calendarYear() {
      return this.$store.getters['corporateActionsCalendar/calendarYear'];
    },
    calendarMonth() {
      return this.$store.getters['corporateActionsCalendar/calendarMonth'];
    },
    calendarDate() {
      return dayjs(`${this.calendarYear}/${this.calendarMonth}/01`);
    },
    isCurrentMonth() {
      return this.today.isSame(this.calendarDate, 'month');
    },
    selectedDate() {
      return this.$store.getters['corporateActionsCalendar/selectedDate'];
    },
    calendarWeeks() {
      return this.weeks ? this.weeks.map((week) =>
        week.days.map((day) => {
          const action = this.actions.find((action) => action.date == day.date);
          const dayjsDate = dayjs(day.date);
          return {
            day: dayjsDate.date(),
            date: dayjsDate,
            color: this.color(dayjsDate, day.isOpen, day.isCurrentMonth),
            isOpen: day.isOpen,
            isCurrentMonth: day.isCurrentMonth,
            isToday: dayjsDate.isSame(this.today),
            count: action ? action.count : 0,
          }
        })
      ) : [];
    },
    hasPrevMonth() {
      return this.$store.getters['corporateActionsCalendar/hasPrevMonth'];
    },
    hasNextMonth() {
      return this.$store.getters['corporateActionsCalendar/hasNextMonth'];
    },
    isVisibleMonthPicker() {
      return this.$store.getters['corporateActionsCalendar/isVisibleMonthPicker'];
    },
  },
  apollo: {
    corporateActionsCalendar: {
      query: QUERY_CORPORATE_ACTIONS_CALENDAR,
      variables() {
        return {
          action: this.actionType,
          year: this.calendarYear,
          month: this.calendarMonth,
        };
      }
    }
  },
  mounted() {
    this.initCalendar();
  },
  methods: {
    initCalendar() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.commit('corporateActionsCalendar/calendarYear', this.today.year());
      this.$store.commit('corporateActionsCalendar/calendarMonth', this.today.month() + 1);
      this.$store.commit('corporateActionsCalendar/calendarPeriod', {
        periodFrom: this.calendarPeriodFrom,
        periodTo: this.calendarPeriodTo,
      });
    },
    /**
     * 前の月の情報を表示
     */
    prevMonth() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.dispatch('corporateActionsCalendar/prevMonth');
    },
    /**
     * 次の月の情報を表示
     */
    nextMonth() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
      this.$store.dispatch('corporateActionsCalendar/nextMonth');
    },
    /**
     * 日付の色を返す
     * currentMonthでない場合は日付文字をグレー
     * 土曜なら青、日曜なら赤、それ以外の日にisOpen==falseなら赤
     */
    color(date, isOpen, isCurrentMonth) {
      if (!isCurrentMonth) {
        return 'text-slate-400';
      } else if (date.day() == 6) {
        return 'text-sky-600';
      } else if (!isOpen || date.day() == 0) {
        return 'text-red-500';
      } else {
        return 'text-slate-500';
      }
    },
    showMonthPicker() {
      this.$store.dispatch('corporateActionsCalendar/showMonthPicker');
    },
    /**
     * MonthPickerを非表示にする
     */
    hideMonthPicker() {
      this.$store.dispatch('corporateActionsCalendar/hideMonthPicker');
      this.$store.commit('corporateActionsCalendar/pickerYear', null);
    },
    selectDate(date, e) {
      if(e) {
        e.preventDefault();
      }
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.commit('corporateActionsCalendar/selectedDate', date);
    },
    isSelectedDate(date) {
      return date.isSame(this.selectedDate);
    },
  }
};
</script>
