<template src="./CorporateActionsStockTransfers.html"></template>
<script>
import { QUERY_CORPORATE_ACTIONS_STOCK_TRANSFERS } from '../../../queries/CorporateActionsStockTransfersQuery';
import dayjs from 'dayjs';
import TailwindPagination from '../../Pagination/TailwindPagination.vue';

const actionNames = {
  split: '分割',
  reverseSplit: '併合',
};

export default {
  name: 'CorporateActionsStockTransfers',
  components: {
    TailwindPagination,
  },
  props: {
    actionType: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      actionName: actionNames[this.actionType],
      corporateActionsStockTransfers: {
        collection: [],
        pageInfo: {
          offsetValue: 0,
          totalPage: 1,
          currentPage: 1,
          totalCount: 0,
          count: 0
        }
      },
    };
  },
  computed: {
    collection() {
      return this.corporateActionsStockTransfers.collection.map((item) => {
        const latestTradeWithChange = item.latestTradeWithChange || {};
        const changeRatio = latestTradeWithChange.changeRatio || null;
        const changeRatioString = changeRatio ? this.numFormatWithPlusSign(changeRatio, 1) : '---';
        return {
          ...item,
          date: dayjs(item.date).format('YYYY/MM/DD'),
          latestTradeWithChange: {
            ...latestTradeWithChange,
            price: this.numFormat(latestTradeWithChange.price, 1),
            priceTime: latestTradeWithChange.price ? this.formatPriceTime(latestTradeWithChange.priceTime) : '--:--',
            changeRatio,
            changeRatioString,
          }
        };
      });
    },
    pageInfo() {
      return this.corporateActionsStockTransfers.pageInfo;
    },
    pageStart() {
      if(this.pageInfo.totalCount === 0) return 0;
      return this.pageInfo.offsetValue + 1;
    },
    pageEnd() {
      return this.pageInfo.offsetValue + this.pageInfo.count;
    },
    calendarYear() {
      return this.$store.getters['corporateActionsCalendar/calendarYear'];
    },
    calendarMonth() {
      return this.$store.getters['corporateActionsCalendar/calendarMonth'];
    },
    selectedDate() {
      return this.$store.getters['corporateActionsCalendar/selectedDate'];
    },
    selectedYear() {
      return this.selectedDate && this.selectedDate.year() || null;
    },
    selectedMonth() {
      return this.selectedDate && this.selectedDate.month() + 1 || null;
    },
    selectedDay() {
      return this.selectedDate && this.selectedDate.date() || null;
    },
    isDateSelected() {
      return this.selectedDate !== null;
    },
    currentPage() {
      return this.$store.getters['tailwindPagination/currentPage'];
    }
  },
  apollo: {
    corporateActionsStockTransfers: {
      query: QUERY_CORPORATE_ACTIONS_STOCK_TRANSFERS,
      variables() {
        if (this.isDateSelected) {
          return {
            action: this.actionType,
            year: this.selectedYear,
            month: this.selectedMonth,
            day: this.selectedDay,
            page: this.currentPage,
          };
        } else {
          return {
            action: this.actionType,
            year: this.calendarYear,
            month: this.calendarMonth,
            page: this.currentPage,
          };
        }
      }
    }
  },
  mounted() {
    this.$store.commit('tailwindPagination/currentPage', this.pageInfo.currentPage);
  },
  methods: {
    clearSelectedDate() {
      this.$store.dispatch('tailwindPagination/resetPage');
      this.$store.dispatch('corporateActionsCalendar/clearSelectedDate');
    },
    changeRatioColor(changeRatio) {
      if (changeRatio > 0) {
        return 'text-minkabuRedUp';
      } else if (changeRatio < 0) {
        return 'text-minkabuBlueDown';
      } else {
        return 'text-minkabuGrayEven';
      }
    }
  }
};
</script>
